import * as React from "react";
import styled from "styled-components";
import { OfferHeader } from "./OfferHeader";
import {
  Lerp,
  ScreenSizes,
  getScreenSize,
  screenWidth,
  useScreenSize,
} from "../../shared-code";
import { ExpirationTypes } from "./ExpirationBadge";
import DailyOfferFooter from "./DailyOfferFooter";
import { DailyOrSpecialOffer, award } from "../DataModels";
import { FlightPassOfferHeader } from "./FlightPassOfferHeader";
import FlightPassOfferFooter from "./FlightPassOfferFooter";

const OuterWrapper = styled.div`
  position: relative;
  height: 444px;
`;

const Wrapper = styled.div<{ consumed: boolean }>`
  position: relative;
  width: 100%;
  height: 444px;
  background-position: right;
  background-size: cover;
  border-radius: 8px;
  cursor: ${(props) => (props.consumed ? "arrow" : "pointer")};
  justify-self: center;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 364px;
  gap: 20px;
  width: 100%;
  z-index: 1;

  @media (max-width: ${ScreenSizes.narrow}) {
    height: 334px;
    margin-top: 30px;
  }
`;

const BackgroundImageContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BackgroundImage = styled.img`
  height: 444px;
  background-repeat: no-repeat;
`;

const Sticker = styled.div`
  position: absolute;
  width: 118px;
  height: 118px;
  top: 10px;
  right: 10px;
  background-color: #f34a4a;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2;
  box-shadow: 0.678px 1.356px 0.678px rgba(0, 0, 0, 0.6);

  @media (max-width: ${ScreenSizes.tablet}) {
    width: 80px;
    height: 80px;
  }

  @media (max-width: ${ScreenSizes.mobile}) {
    top: 40px;
  }
`;

const FirstRow = styled.span`
  font-size: 20px;

  @media (max-width: ${ScreenSizes.tablet}) {
    font-size: 13.5px;
  }
`;
const SecondRow = styled.span`
  font-size: 56px;

  @media (max-width: ${ScreenSizes.tablet}) {
    font-size: 38px;
  }
`;
const ThirdRow = styled.span`
  font-size: 24px;

  @media (max-width: ${ScreenSizes.tablet}) {
    font-size: 16.3px;
  }
`;

function getImgSourceString(seasonNumber: number) {
  const screenSize = useScreenSize();
  let formattedScreenSize = "Extra Narrow";
  switch (screenSize) {
    case "desktop":
      formattedScreenSize = "Desktop";
      break;
    case "laptop":
      formattedScreenSize = "Laptop";
      break;
    case "tablet":
      formattedScreenSize = "Tablet";
      break;
    case "mobile":
      formattedScreenSize = "Mobile";
  }

  return `https://starform-playmetalstorm-assets.s3.us-west-2.amazonaws.com/flight-passes/${seasonNumber}/${formattedScreenSize}.png`;
}

export interface FlightPassOfferCardProps {
  offer: DailyOrSpecialOffer;
  purchaseFn: () => void;
  onClick: () => void;
}

export default (props: FlightPassOfferCardProps) => {
  if (props.offer.expires != undefined && props.offer.expires <= 0) {
    return null;
  }
  const localizedAmount = Intl.NumberFormat("en-US").format(
    props.offer.awards[0]?.amount || 0
  );

  return (
    <OuterWrapper>
      <FlightPassOfferHeader
        loyaltyPoints={props.offer.loyaltyPoints}
        expiration={undefined}
        serverOffset={props.offer.serverOffset}
        expirationType={undefined}
        stickerText={props.offer.stickerText}
      />
      <Wrapper
        onClick={props.purchaseFn}
        consumed={props.offer.consumed || false}
      >
        <Sticker>
          <FirstRow>Up to</FirstRow>
          <SecondRow>15x</SecondRow>
          <ThirdRow>VALUE</ThirdRow>
        </Sticker>
        <CardBody />
        <BackgroundImageContainer>
          <BackgroundImage
            src={getImgSourceString(props.offer.seasonNumber || 33)}
          />
        </BackgroundImageContainer>
        <FlightPassOfferFooter
          title={props.offer.name}
          subTitle={props.offer.stickerText || ""}
          {...props.offer.price}
        />
      </Wrapper>
    </OuterWrapper>
  );
};
