import * as React from "react";
import styled from "styled-components";
import { OfferHeader } from "./OfferHeader";
import { OfferFooter } from "./OfferFooter";
import { ScreenSizes } from "../../shared-code";
import { ExpirationTypes } from "./ExpirationBadge";
import { DailyOrSpecialOffer } from "../DataModels";

const OuterWrapper = styled.div`
  position: relative;
  max-width: 685px;
  height: 315px;
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 685px;
  height: 315px;
  background-position: right;
  background-size: cover;
  border-radius: 8px;
  cursor: pointer;
  justify-self: center;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 270px;
  padding: 50px 15px 5px 15px;
  width: 300px;
  z-index: 1;

  @media (max-width: ${ScreenSizes.tablet}) {
    width: 260px;
  }

  @media (max-width: ${ScreenSizes.mobile}) {
    width: 300px;
  }

  @media (max-width: ${ScreenSizes.narrow}) {
    width: 100%;
  }
`;

const Name = styled.div`
  height: 76px;
  font-size: 36px;
  font-weight: 600;
  line-height: 105%;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  z-index: 0;

  @media (max-width: ${ScreenSizes.tablet}) {
    width: 250px;
    font-size: 34px;
  }

  @media (max-width: ${ScreenSizes.mobile}) {
    width: 340px;
  }

  @media (max-width: ${ScreenSizes.narrow}) {
    width: 180px;
  }
`;

const Awards = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 155px;
`;

const AwardLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 43px;
  background: rgba(80, 80, 80, 0.3);
  gap: 5px;
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
  text-shadow: 0.5px 1px 0px rgba(0, 0, 0, 0.5);
`;

const AwardText = styled.span<{ leftPadding?: boolean | false }>`
  display: inline-flex;
  gap: 5px;
  padding-top: 3px;
  padding-left: ${(props) => (props.leftPadding ? "5px" : 0)};
`;

const Sticker = styled.div`
  position: absolute;
  right: 15px;
  top: 15px;
  background-color: #f34a4a;
  border-radius: 50%;
  height: 92px;
  aspect-ratio: 1 / 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 1px 2px 1px rgba(0, 0, 0, 0.6);
  font-weight: 600;
  padding-top: 10px;
  text-shadow: 0.5px 1px 0 #000;
  z-index: 1;

  @media (max-width: ${ScreenSizes.narrow}) {
    height: 64px;
    padding-top: 5px;
  }
`;

const StickerMain = styled.span`
  font-size: 36px;

  @media (max-width: ${ScreenSizes.narrow}) {
    font-size: 24px;
  }
`;

const StickerSub = styled.span`
  font-size: 22px;

  @media (max-width: ${ScreenSizes.narrow}) {
    font-size: 20px;
  }
`;

const BackgroundImage = styled.img`
  position: absolute;
  top: 0;
  left: calc(100% - 685px);
  width: 685px;
  background-repeat: no-repeat;
`;

const BannerImage = styled.img`
  position: absolute;
  top: 135px;
  right: 5px;
  width: 550px;
  box-shadow: 1px 1px 5px black;
`;

const PilotImage = styled.img`
  position: absolute;
  top: 100px;
  right: 300px;
  width: 80px;
  rotate: -15deg;
  box-shadow: 1px 1px 5px black;

  @media (max-width: ${ScreenSizes.tablet}) {
    right: 120px;
    rotate: 15deg;
  }

  @media (max-width: ${ScreenSizes.mobile}) {
    right: 300px;
    rotate: -15deg;
  }

  @media (max-width: ${ScreenSizes.narrow}) {
    right: 120px;
    rotate: 15deg;
  }
`;

const DecalImage = styled.img`
  position: absolute;
  top: 175px;
  right: 180px;
  width: 100px;
  rotate: 15deg;

  @media (max-width: ${ScreenSizes.tablet}) {
    rotate: -15deg;
  }

  @media (max-width: ${ScreenSizes.mobile}) {
    rotate: 15deg;
  }

  @media (max-width: ${ScreenSizes.narrow}) {
    rotate: -15deg;
  }
`;

interface award {
  text: string;
  icon: string;
}

export interface SpecialOfferCardProps {
  offer: DailyOrSpecialOffer;
  purchaseFn: () => void;
  onClick: () => void;
}

export default (props: SpecialOfferCardProps) => {
  let bannerName = "";
  let pilotName = "";
  props.offer.awards.forEach((award) => {
    if (award.text == "Pilot Icon") {
      pilotName = award.icon || "";
    } else if (award.text == "Pilot Banner") {
      bannerName = award.icon || "";
    }
  });

  const bannerUrl = `https://starform-web-public.s3.us-west-2.amazonaws.com/aircombat/player-banners/pilot-banner-${bannerName}.png`;
  const pilotUrl = `https://starform-web-public.s3.us-west-2.amazonaws.com/aircombat/player-icons/icon-pilot-${pilotName}.png`;
  const decalUrl = `https://starform-playmetalstorm-assets.s3.us-west-2.amazonaws.com/flight-passes/${props.offer.seasonNumber}/cosmetic-bundle/decal.png`;
  const backgroundUrl = `https://starform-playmetalstorm-assets.s3.us-west-2.amazonaws.com/flight-passes/${props.offer.seasonNumber}/cosmetic-bundle/background.png`;

  return (
    <OuterWrapper>
      <OfferHeader
        loyaltyPoints={props.offer.loyaltyPoints}
        expiration={props.offer.expires}
        expirationType={ExpirationTypes.expiry}
        serverOffset={props.offer.serverOffset}
      />
      <Wrapper onClick={props.onClick}>
        <CardBody>
          <Name>{props.offer.name}</Name>
          <Awards>
            {props.offer.awards.map((award) => {
              return (
                <AwardLine>
                  <AwardText leftPadding={true}>
                    <span>{award.amount}</span> <span>{award.text}</span>
                  </AwardText>
                </AwardLine>
              );
            })}
          </Awards>
        </CardBody>
        {props.offer.stickerText != undefined ? (
          <Sticker>
            <StickerMain>{props.offer.stickerText.split(" ")[0]}</StickerMain>
            <StickerSub>{props.offer.stickerText.split(" ")[1]}</StickerSub>
          </Sticker>
        ) : null}
        <BackgroundImage src={backgroundUrl} />
        <BannerImage src={bannerUrl} />
        <PilotImage src={pilotUrl} />
        <DecalImage src={decalUrl} />
        <OfferFooter stickerText={null} {...props.offer.price} />
      </Wrapper>
    </OuterWrapper>
  );
};
