import * as React from "react";
import styled from "styled-components";
import StarPointIcon from "../../images/StarPointIcon.png";
import { ScreenSizes, useWindowWidth } from "../../shared-code";
import silverIcon from "../../images/SpecialOffer/silver.png";

const Wrapper = styled.div`
  position: absolute;
  z-index: 2;

  @media (max-width: ${ScreenSizes.mobile}) {
    width: 100%;
  }
`;

const StickerText = styled.div`
  padding: 4px 5px;
  line-height: 30px;
  gap: 4px;
  border-bottom-right-radius: 14px;
  border-top-left-radius: 8px;
  background: #f34a4a;
  box-shadow: 0.5px 2px 1px 0px rgba(0, 0, 0, 0.4);
  text-shadow: 0.5px 1px 0.5px rgba(0, 0, 0, 0.4);
  font-weight: 600;
  font-size: 20px;
  color: white;
  padding-inline: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;

  & img {
    height: 52px;
    aspect-ratio: 1 / 1;
  }

  @media (max-width: ${ScreenSizes.mobile}) {
    width: 100%;
    border-bottom-right-radius: 0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    height: 30px;
    font-size: 18px;
    text-align: center;
    justify-content: center;

    & img {
      height: 26px;
      aspect-ratio: 1 / 1;
    }
  }
`;

const InnerText = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 20px;
  font-weight: 600;
  line-height: 100%;

  @media (max-width: ${ScreenSizes.mobile}) {
    flex-direction: row;
    gap: 5px;
  }
`;

const StarPointWrapper = styled.div<{ hasSticker: boolean }>`
  display: inline-flex;
  height: 36px;
  padding: 4px 5px;
  align-items: center;
  gap: 4px;
  border-top-left-radius: ${(props) => (props.hasSticker ? "0px" : "10px")};
  border-bottom-right-radius: 10px;
  background: rgba(255, 255, 255, 0.8);
  font-weight: 600;
  font-size: 20px;
  color: black;

  & > img {
    height: 20px;
    aspect-ratio: 1 / 1;
  }
`;

interface StarPointBadgeProps {
  points: number;
  stickerText?: string | undefined;
}
//TODO: Remove +2,500 Squadron Silver line on narrow
export const FlightPassStarPointBadge = ({ points }: StarPointBadgeProps) => {
  return (
    <Wrapper>
      <StickerText>
        <img src={silverIcon} alt="silver" />
        {useWindowWidth() > 500 ? (
          <InnerText>
            <span>WEB ONLY BONUS!</span>
            <span>+2,500 Squadron Silver!</span>
          </InnerText>
        ) : (
          <InnerText>WEB ONLY SILVER BONUS!</InnerText>
        )}
      </StickerText>
      <StarPointWrapper hasSticker={true}>
        {"+" + points.toLocaleString()}
        <img src={StarPointIcon} />
      </StarPointWrapper>
    </Wrapper>
  );
};
