import * as React from "react";
import styled from "styled-components";
import { ScreenSizes, getScreenSize, useScreenSize } from "../../shared-code";
import { prependOnceListener } from "process";

const CardFooter = styled.div`
  height: 80px;
  display: flex;
  justify-content: space-between;
  padding-inline: 20px;
  color: #ffffff;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1;
  align-items: center;
  isolation: isolate;

  @media (max-width: ${ScreenSizes.tablet}) {
    padding-inline: 10px;
  }

  @media (max-width: ${ScreenSizes.mobile}) {
    padding-inline: 10px;
  }

  @media (max-width: ${ScreenSizes.narrow}) {
    flex-direction: column;
    height: 150px;
    padding-block: 18px;
    align-items: flex-start;
  }
`;

const LeftTextColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #ffffff;
  height: 48px;

  @media (max-width: ${ScreenSizes.narrow}) {
    align-items: flex-start;
  }
`;

const TitleText = styled.span`
  font-size: 24px;

  @media (max-width: ${ScreenSizes.tablet}) {
    font-size: 20px;
  }

  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 24px;
  }
`;

const SubTitleText = styled.span`
  font-size: 20px;

  @media (max-width: ${ScreenSizes.tablet}) {
    font-size: 18px;
  }

  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 20px;
  }
`;

const PurchaseButton = styled.div<{ consumed: boolean }>`
  display: flex;
  width: 160px;
  height: 65px;
  padding: 3px 0px 1px 0px;
  border-radius: 8px;
  box-shadow: 0.5px 1px 0px 0px rgba(0, 0, 0, 0.4);
  justify-content: center;
  align-items: center;
  gap: 5px;
  flex-shrink: 0;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background-color: ${(props) =>
    props.consumed ? "$777777" : "rgb(0, 186, 141)"};

  @media (max-width: ${ScreenSizes.tablet}) {
    font-size: 24px;
  }

  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 30px;
  }

  @media (max-width: ${ScreenSizes.narrow}) {
    flex-direction: column;
    width: 100%;
    height: 55px;
  }
`;

type OfferFooterProps = {
  currency: string | undefined;
  amount?: number | undefined;
  consumed: boolean;
  titleText: string;
  subTitleText: string;
};

export default ({
  currency,
  amount,
  consumed,
  titleText,
  subTitleText,
}: OfferFooterProps) => {
  const localizedCost =
    amount != undefined && amount != 0
      ? Intl.NumberFormat("en-US", {
          style: "currency",
          currency: currency,
        }).format(amount)
      : null;

  return (
    <CardFooter>
      <LeftTextColumn>
        <TitleText>{titleText}</TitleText>
        <SubTitleText>{subTitleText}</SubTitleText>
      </LeftTextColumn>
      {consumed ? (
        <PurchaseButton consumed={true}>
          {localizedCost != null ? "PURCHASED" : "COLLECTED"}
        </PurchaseButton>
      ) : (
        <PurchaseButton consumed={false}>
          {localizedCost || "COLLECT"}
        </PurchaseButton>
      )}
    </CardFooter>
  );
};
