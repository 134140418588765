import * as React from "react";
import styled, { keyframes } from "styled-components";
import { DailyOrSpecialOffer } from "../DataModels";
import { ContentBlock } from "../ContentBlock";
import { ScreenSizes } from "../../shared-code";
import CustomTimer from "../shared/CustomTimer";
import { ExpirationTypes } from "./ExpirationBadge";
import FlightPassCard from "./FlightPassCard";

const LoyaltyCardGrid = styled.div`
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
`;

const LoyaltyPointHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  padding-bottom: 20px;

  @media (max-width: ${ScreenSizes.tablet}) {
    flex-direction: column;
    gap: 10px;
  }

  @media (max-width: ${ScreenSizes.narrow}) {
    align-items: center;
    gap: 10px;
  }
`;

const LoyaltyPointHeaderText = styled.div`
  max-width: 820px;
  font-size: 20px;
  line-height: 140%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;

  @media (max-width: ${ScreenSizes.tablet}) {
    max-width: 100%;
  }

  @media (max-width: ${ScreenSizes.narrow}) {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;

const BubbleDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;

  @media (max-width: ${ScreenSizes.narrow}) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const ShopTimer = styled.div`
  display: inline-flex;
  height: 35px;
  padding: 3px 16px 3px 10px;
  align-items: center;
  justify-content: center;
  gap: 6px;
  border-radius: 36px;
  background: rgba(0, 0, 0, 0.7);
  font-weight: 600;
  font-size: 18px;
  color: white;
  min-width: 300px;

  & span {
    padding-top: 3px;
  }
`;

const TimerImg = styled.img`
  height: 20px;
  aspect-ratio: 1 / 1;
`;

const OuterWrapper = styled.div`
  position: relative;
  height: 444px;
  cursor: pointer;
`;

const ShowContents = styled.div`
  width: 130px;
  min-width: 130px;
  height: 36px;
  display: flex;
  padding: 3.333px 16px 3.333px 10px;
  justify-content: flex-end;
  align-items: center;
  gap: 6px;
  border-radius: 36px;
  border: 1px solid rgba(255, 255, 255, 0.8);
  background: rgba(0, 0, 0, 0.7);
`;

const overlay = keyframes`
  from {
    backdrop-filter: blur(15px);
  }

  to {
    backdrop-filter: none;
  }
`;

const contentFade = keyframes`
    from {

    opacity: 1;
  }

  to {
    opacity: .25;
  }
`;

const BlurLayer = styled.div<{ blur: boolean }>`
  display: ${(props) => (props.blur == true ? "flex" : "none")};
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: absolute;
  width: 100%;
  height: 444px;
  padding-inline: 20px;
  border-radius: 8px;
  border: 1px rgba(255, 255, 255, 0.2);
  background: rgba(0, 0, 0, 0.4);
  z-index: 3;
  backdrop-filter: blur(15px);
  animation: ${overlay} 500ms 5500ms linear forwards;

  @media (max-width: ${ScreenSizes.narrow}) {
    padding-inline: 10px;
  }

  & > span,
  h2,
  h3 {
    animation: ${contentFade} 500ms linear forwards;
    animation-delay: 5500ms;
  }

  & > span {
    width: 100%;
    color: #fff;
    text-align: center;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
    font-size: 24px;
    font-weight: 400;
    line-height: 140%; /* 26px */
    margin-block: 0;

    @media (max-width: ${ScreenSizes.narrow}) {
      font-size: 18px;
    }
  }

  & > h3 {
    color: #fff;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
    font-size: 33px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    margin-block: 0;

    @media (max-width: ${ScreenSizes.narrow}) {
      font-size: 22px;
    }
  }

  & > h4 {
    color: #fff;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    margin-block: 0;

    @media (max-width: ${ScreenSizes.narrow}) {
      font-size: 20px;
    }
  }

  & > .gold {
    color: rgba(255, 177, 0, 1);
  }

  & .red {
    border-radius: 5px;
    background: #f34a4a;
    box-shadow: 0.5px 2px 1px 0px rgba(0, 0, 0, 0.4);
    padding: 3px 5px 0 5px;
  }
`;

interface FlightPassSectionProps {
  offer: DailyOrSpecialOffer;
  purchaseFn: () => void;
}

export default (props: FlightPassSectionProps) => {
  const [noAffordShown, setNoAffordShown] = React.useState(false);
  const levelsGiven = props.offer.stickerText
    ?.split("+")[1]
    .split(" ")[0]
    .trim();
  return (
    <ContentBlock id={"flightpass"} title="Flight Pass">
      <LoyaltyPointHeader>
        <LoyaltyPointHeaderText>
          <span>
            Purchasing the Flight Pass Bundle on the Web Shop awards an EXTRA
            250 Silver for 10 Squadron members!
          </span>
        </LoyaltyPointHeaderText>
        <BubbleDiv>
          {props.offer.expires != undefined && (
            <ShopTimer>
              <TimerImg src={ExpirationTypes.expiry} />
              <span>
                Remaining Flight Pass{" "}
                <CustomTimer
                  expirationTime={props.offer.expires}
                  serverOffset={props.offer.serverOffset || 0}
                />
              </span>
            </ShopTimer>
          )}
          <ShowContents
            onClick={() => {
              if (noAffordShown == false) {
                setNoAffordShown(true);
                window.setTimeout(() => {
                  setNoAffordShown(false);
                }, 6000);
              } else {
                setNoAffordShown(false);
              }
            }}
          >
            <span>Show Contents</span>
          </ShowContents>
        </BubbleDiv>
      </LoyaltyPointHeader>
      <LoyaltyCardGrid>
        <OuterWrapper>
          <BlurLayer
            blur={noAffordShown}
            onClick={() => setNoAffordShown(false)}
          >
            <h3>FLIGHT PASS CONTENTS</h3>
            <span>
              80&nbsp;Aircraft&nbsp;Credits
              •&nbsp;5,000&nbsp;Universal&nbsp;Parts
              •&nbsp;4&nbsp;Huge&nbsp;Crates •&nbsp;13&nbsp;Medium&nbsp;Crates
              •&nbsp;15,000&nbsp;Silver •&nbsp;4&nbsp;Aircraft&nbsp;Decals
              •&nbsp;2&nbsp;Profile&nbsp;Icons
              •&nbsp;2&nbsp;Profile&nbsp;Banners
            </span>
            <span className="gold">Golden player name effect</span>
            <h4>FLIGHT PASS BUNDLE</h4>
            <span className="gold">+{levelsGiven} Instant Levels!</span>
            <span>
              <span className="red">WEB EXCLUSIVE</span>{" "}
              <span>
                Includes a gift of 750 SILVER for 10 Squadron Members!
              </span>
            </span>
          </BlurLayer>
          <FlightPassCard
            offer={props.offer}
            purchaseFn={props.purchaseFn}
            onClick={() => null}
          />
        </OuterWrapper>
      </LoyaltyCardGrid>
    </ContentBlock>
  );
};
