import * as React from "react";
import styled from "styled-components";
import { ScreenSizes, useWindowWidth } from "../../shared-code";

const CardFooter = styled.div`
  height: 80px;
  display: flex;
  justify-content: space-between;
  padding-inline: 20px;
  color: #ffffff;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1;
  align-items: center;
  isolation: isolate;

  @media (max-width: ${ScreenSizes.mobile}) {
    flex-direction: column;
    height: 150px;
    padding-block: 18px;
    align-items: flex-start;
    gap: 12px;
  }
`;

const LeftTextColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #ffffff;
  height: 48px;

  @media (max-width: ${ScreenSizes.narrow}) {
    align-items: flex-start;
  }
`;

const TitleText = styled.span`
  font-size: 28px;
  text-shadow: 0.5px 1px 0px rgba(0, 0, 0, 0.4);

  @media (max-width: ${ScreenSizes.tablet}) {
    font-size: 24px;
  }

  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 24px;
  }
`;

const SubTitleText = styled.span`
  display: flex;
  font-size: 22px;
  text-shadow: 0.5px 1px 0px rgba(0, 0, 0, 0.4);
  gap: 5px;

  @media (max-width: ${ScreenSizes.tablet}) {
    font-size: 18px;
  }

  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 20px;
  }
`;

const Gold = styled.div`
  color: rgba(255, 177, 0, 1);
`;

const PurchaseButton = styled.div`
  display: flex;
  width: 160px;
  height: 65px;
  padding: 3px 0px 1px 0px;
  border-radius: 8px;
  box-shadow: 0.5px 1px 0px 0px rgba(0, 0, 0, 0.4);
  justify-content: center;
  align-items: center;
  gap: 5px;
  flex-shrink: 0;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background-color: rgb(0, 186, 141);

  @media (max-width: ${ScreenSizes.tablet}) {
    font-size: 24px;
  }

  @media (max-width: ${ScreenSizes.mobile}) {
    font-size: 30px;
    flex-direction: column;
    width: 100%;
    height: 55px;
  }
`;

type OfferFooterProps = {
  currency: string | undefined;
  amount?: number | undefined;
  title: string;
  subTitle: string;
};

export default ({ currency, amount, title, subTitle }: OfferFooterProps) => {
  const [titleFirstElement, titleSecondElement] = title.split(":");
  const [subTitleFirstElement, subTitleSecondElement] = subTitle.split("+");
  const localizedCost =
    amount != undefined && amount != 0
      ? Intl.NumberFormat("en-US", {
          style: "currency",
          currency: currency,
        }).format(amount)
      : null;

  return (
    <CardFooter>
      <LeftTextColumn>
        <TitleText>
          {useWindowWidth() > 500 ? title : titleFirstElement.trim()}
        </TitleText>

        {useWindowWidth() > 500 ? (
          <SubTitleText>
            <span>{subTitleFirstElement.trim()}</span>
            <Gold>{" + " + subTitleSecondElement.trim()}</Gold>
          </SubTitleText>
        ) : (
          <SubTitleText>
            <Gold>{"Includes " + subTitleSecondElement.trim()}</Gold>
          </SubTitleText>
        )}
      </LeftTextColumn>
      <PurchaseButton>{localizedCost}</PurchaseButton>
    </CardFooter>
  );
};
